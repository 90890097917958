<template>


    <v-container fluid>
        <v-row>
            <v-col class="mb-5 col-md-6 col-sm-12" v-if="3 == 3">
                <div class="display-1 font-weight-thin mb-2">hoerschaden Stream</div>
                <div class="title mb-3">nächster Stream: {{ nextDate }}</div>

                <div class="title mb-3" v-if="infoText" v-html="infoText"></div>

                <div v-if="twitch != ''" class="mb-3">
                    <iframe :src="'https://player.twitch.tv/?parent=live.hoerschaden.de&channel=' + twitch"
                            frameborder="0"
                            allowfullscreen="true"
                            scrolling="no"
                            height="378"
                            width="620">
                    </iframe>
                    <a href="https://www.twitch.tv/ohschonhell?tt_content=text_link&tt_medium=live_embed"
                       target="_blank">
                        <h3>Live-Video von www.twitch.tv ansehen</h3>
                    </a>
                </div>

                <div>
                    <div class="headline mb-3 pt-3">Stream History:</div>
                    <vue-video-player date="12. April" src="http://ohschonhell.de/media/2020-04-12 17-05-37_2.mp4" dj="Rainer Wachtelborn"></vue-video-player>
                    <vue-video-player date="28. März" src="http://ohschonhell.de/media/2020-03-28%2020-10-07.mp4" dj="Rainer Wachtelborn"></vue-video-player>
                </div>



            </v-col>
            <v-col class="mb-5 col-md-6 col-sm-12">
                <div class="pr-2">
                    <chat-component v-if="showChat"></chat-component>
                </div>
            </v-col>
        </v-row>

    </v-container>


</template>

<script>
    // @ is an alias to /src
    import ChatComponent from '@/components/Chat.vue'
    import VueVideoPlayer from '@/components/VueVideoPlayer.vue'
    import TwitchVideoPlayer from '@/components/TwitchVideoPlayer.vue'


    export default {
        name: 'Home',
        components: {
            ChatComponent,
            VueVideoPlayer,
            TwitchVideoPlayer
        },

        data: () => ({
            twitch: window.twitch,
            nextDate: '???',
            infoText: false,
            showChat: true
        }),


        mounted: function () {
            if(window.nextDate != '') {
                this.nextDate = window.nextDate;
            }

            if(window.infoText != '') {
                this.infoText = window.infoText;
            }

            if(window.showChat != '') {
                this.showChat = window.showChat;
            }
        },

        methods: {


        },


    }
</script>

<style less>

    .v-application {
        background-color: #FEB500 !important;
    }

    .messageArea {
        height: ~ calc(100% - 100px);
    }

    .player {
        position: absolute !important;
        width: 100%;
        height: 60%;
    }

    .vjs-custom-skin {
        height: 60% !important;
    }

    .vjs-custom-skin /deep/ .video-js {
        height: 60%;
    }

    .video-js .vjs-big-play-button {
        left: 45%;
        top: 45%
    }


    @media (max-width: 640px) {
        iframe {
            width: 100%;
        }
    }

</style>
