<template>

    <div class="mb-3">
        <h2>Stream vom {{ date }}</h2>
        <h3 class="mb-1">mit: {{dj }}</h3>
        <v-btn outlined v-on:click="showVideo = true" v-if="!showVideo">anzeigen</v-btn>
        <div v-if="showVideo">
            <v-btn outlined v-on:click="showVideo = false" class="mb-2">ausblenden</v-btn>
            <div>
                <iframe
                    src="https://player.twitch.tv/?autoplay=false&video=v602493937&parent=localhost"
                    frameborder="0"
                    allowfullscreen="true"
                    scrolling="no"
                    height="378"
                    width="620">
                </iframe>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'Home',
        components: {},

        props: {
            date: '',
            src: '',
            dj: ''
        },

        data: () => ({
            showVideo: false
        }),


        mounted: function () {

        },

        methods: {
            player_03_28: function () {
                const element = document.querySelector(".stream-03-28");
                const player = new THEOplayer.Player(element, {
                    libraryLocation: "https://cdn.myth.theoplayer.com/4af638e4-85fb-4938-b9b7-47e27cc59305"
                });

                player.source = {
                    sources: [{
                        "src": "http://ohschonhell.de/media/2020-03-28%2020-10-07.mp4",
                        "type": "video/mp4"
                    }]
                };

                player.preload = 'no';

                // Related content configuration
                player.related.sources = [{
                    "image": "//cdn.theoplayer.com/video/big_buck_bunny/poster.jpg",
                    "title": "Big buck bunny",
                    "duration": "9:57",
                    "source": {
                        "sources": [{
                            "src": "//cdn.theoplayer.com/video/big_buck_bunny/big_buck_bunny.m3u8",
                            "type": "application/x-mpegurl"
                        }]
                    }
                }, {
                    "image": "//cdn2.hubspot.net/hubfs/2163521/Demo_zone/CaminandesLlamaDramaPoster.jpg",
                    "title": "4K Streaming with THEOplayer",
                    "duration": "1:30",
                    "source": {
                        "sources": [{
                            "src": "//amssamples.streaming.mediaservices.windows.net/634cd01c-6822-4630-8444-8dd6279f94c6/CaminandesLlamaDrama4K.ism/manifest(format=m3u8-aapl)",
                            "type": "application/x-mpegurl"
                        }]
                    }
                }, {
                    "image": "//cdn.theoplayer.com/video/sintel/poster.jpg",
                    "title": "Sintel",
                    "duration": "14:47",
                    "source": {
                        "sources": [{
                            "src": "https://cdn.theoplayer.com/video/sintel/nosubs.m3u8",
                            "type": "application/x-mpegurl"
                        }]
                    }
                }, {
                    "image": "//cdn2.hubspot.net/hubfs/2163521/Demo_zone/tears_of_steel_poster.jpg",
                    "title": "Tears of steel",
                    "duration": "14:47",
                    "source": {
                        "sources": [{
                            "src": "https://cdn.theoplayer.com/video/elephants-dream/playlist-single-audio.m3u8",
                            "type": "application/x-mpegurl"
                        }]
                    }
                }];
                // Up next configuration
                player.upnext.bar.offset = 100;
                player.upnext.source = {
                    "image": "//cdn.theoplayer.com/video/big_buck_bunny/poster.jpg",
                    "title": "Big buck bunny",
                    "duration": "9:57",
                    "link": "#"
                };
                // Social configuration
                player.social.url = "http://demo.theoplayer.com/social-sharing";
                player.social.items = [{
                    "label": "Facebook",
                    "icon": "https://en.facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png",
                    "src": "http://www.facebook.com/sharer/sharer.php?u=<URL>"
                }, {
                    "label": "Twitter",
                    "icon": "https://s-media-cache-ak0.pinimg.com/originals/f3/6f/51/f36f511b261596a2debe85d844bb1b87.png",
                    "src": "http://twitter.com/intent/tweet?url=<URL>"
                }, {
                    "label": "Reddit",
                    "icon": "https://vignette3.wikia.nocookie.net/hayday/images/1/10/Reddit.png/revision/latest?cb=20160713122603",
                    "src": "http://www.reddit.com/submit?url=<URL>"
                }, {
                    "label": "Direct link",
                    "src": "http://demo.theoplayer.com/social-sharing"
                }, {
                    "label": "Embed",
                    "text": "<iframe width=\"640\" height=\"360\" src=\"http://demo.theoplayer.com/social-sharing\" frameborder=\"0\" allowfullscreen>\n</iframe>"
                }];
                /*
                            // OPTIONAL CONFIGURATION
                            // Customized video player parameters
                            player.source = {
                                sources: [{
                                    "src": "http://ec2-54-93-188-102.eu-central-1.compute.amazonaws.com/hls/4d254c6f-a7ae-338e-4ea4-87b2b71f1d3f/play.m3u8",
                                    "type": "application/x-mpegurl"
                                }]

                            };

                            player.preload = 'auto';


                            // Up next configuration
                            player.upnext.bar.offset = 100;

                            // Social configuration
                            player.social.url = "http://demo.theoplayer.com/social-sharing";

                 */
            }

        },

        computed: {
            player() {
                return this.$refs.videoPlayer.player
            }
        },
    }
</script>

<style less>


</style>
