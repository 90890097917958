import Vue from 'vue';
import VuePusher from "vue-pusher";


Vue.use(VuePusher, {
    api_key: '8104b6a18ef3b8b69237',
    options: {
        cluster: 'eu',
        encrypted: true,
    }
});



/*

Vue.use(require('vue-pusher'), {
    api_key: '8104b6a18ef3b8b69237',
    options: {
        cluster: 'eu',
        encrypted: true,
    }
});

*/

