import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import pusher from './plugins/Pusher';

import VueCoreVideoPlayer from './plugins/vueCoreVideoPlayer';


import './plugins/axios';

Vue.use(require('vue-moment'));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
