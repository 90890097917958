<template>

    <div>

        <div v-if="!currentUser">
            <v-text-field
                v-on:submit.prevent="createUser"
                label="Chatname"
                class="mb-2"
                v-model="newName"
            ></v-text-field>
            <div style="text-align: right">
                <v-btn outlined v-on:click="createUser">zum Chat anmelden</v-btn>
            </div>
        </div>


        <div v-if="currentUser">
            <v-form v-on:submit.prevent="sendMessage" class="mb-5">
                <v-text-field
                    :label="'neuer Text (als ' + currentUser + ')'"
                    class="mb-2"
                    v-model="messageText"
                    maxlength="100"
                    counter
                ></v-text-field>
                <div style="text-align: right">
                    <v-btn text v-on:click="signOut" class="mr-3">vom Chat abmelden</v-btn>
                    <v-btn outlined v-on:click="sendMessage">Text senden</v-btn>
                    <div class="text-end caption pt-1">
                        Falls du gerade nicht posten kannst, bitte kurz ab- und mit gleichem Namen wieder einloggen...
                    </div>
                </div>
            </v-form>

            <div class="mt-4">
                <div class="messageArea">
                    <div>
                        <div v-for="message in messages" class="mb-3">
                            <div class="title font-weight-light	">{{ message.text }}</div>
                            <div class="caption">
                                <strong>{{ message.name }}</strong> | {{ message.created_at |
                                moment("D.MM.YYYY h:mm a") }}
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    export default {
        name: 'Home',
        components: {},

        data: () => ({
            newName: '',
            currentUser: null,
            messages: [],
            messageText: ''
        }),


        mounted: function () {
            const me = this;

            if (localStorage.getItem('chatUser')) {
                me.getLastMessages();
                me.currentUser = localStorage.getItem('chatUser');
                me.connectToChat();
            }
        },

        methods: {

            createUser: function () {
                const me = this;

                if (this.newName === '') {
                    return;
                }

                axios.post(`/api/create`, {
                    username: this.newName
                })
                    .then(user => {
                        this.newName = '';
                        me.currentUser = user.data.username;
                        localStorage.setItem('chatUser', user.data.username);
                        me.getLastMessages();
                        me.connectToChat();
                    });
            },

            connectToChat: function () {
                const me = this;

                var channel = this.$pusher.subscribe('hs_chat');
                channel.bind('chat-event', function (data) {
                    me.messages.unshift({
                        name: data.message.name,
                        text: data.message.text,
                        created_at: data.message.created_at
                    });
                });

            },

            sendMessage: function () {
                const me = this;

                if (me.messageText === '') {
                    return;
                }


                axios.post('/api/message', {
                    text: me.messageText
                })
                    .then(respone => {
                        me.messageText = '';
                    });
            },

            signOut: function () {
                const me = this;

                axios.post('/api/logout', )
                    .then(respone => {
                        localStorage.clear();
                        me.currentUser = null;
                        this.$pusher.unsubscribe('hs_chat')
                    });
            },

            getLastMessages: function() {
                const me = this;

                axios.get('/api/messages/50')
                    .then(response => {
                        me.messages = response.data;
                        console.log(response.data);
                    });
            }
        }
    }
</script>

<style less>


</style>
